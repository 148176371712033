<template lang="pug">
div.container
  div(v-if="!member.membershipType")
    loading-spinner(message="Loading member data...")
  div(v-if="member.membershipType")
    b-row(align-h="center").mb-4
        h1 Edit Member

    div.row
      div.col-md-4.d-none.d-md-block
        b-list-group.p-2
          b-list-group-item.d-flex.justify-content-between.align-items-center(variant="info") Membership <b-badge variant="secondary" :href="`/card/individual/${member._id}`"> View Card</b-badge>
          b-list-group-item.d-flex.justify-content-between.align-items-center ID <span>{{member.igdaFinlandId}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Type <span>{{member.membershipType}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Joined <span>{{member.createdDate | moment("MMMM YYYY")}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="member.membershipType != 'Lifetime'") Status <span>{{member.status}}</span>
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="member.membershipType != 'Lifetime'") Expires <span>{{member.validUntil | moment("MMMM YYYY")}}</span>
        b-list-group.p-2
          b-list-group-item(active) Contact Information
          b-list-group-item.d-flex.justify-content-between.align-items-center Email <span><a :href="member.email">{{member.email}}</a></span>
          b-list-group-item.d-flex.justify-content-between.align-items-center Residence <span>{{member.postOffice}}</span>
        b-list-group.p-2(v-if="member.membershipType != 'Lifetime'")
          b-list-group-item(active) Quick Renew/Change Membership
          b-list-group-item.d-flex.justify-content-center
            b-select(v-model="editedMember.membershipType")
              option Individual
              option Unemployed
              option Student
              option Lifetime
            br/
            b-button(variant="warning" @click="renewMembership()" v-show="editedMember.membershipType == member.membershipType") {{status == "VALID" ? "Extend" : "Renew"}}
            b-button(variant="warning" @click="changeMembership()" v-show="editedMember.membershipType != member.membershipType") {{status == "VALID" ? "Change & Extend" : "Change & Renew"}}
        b-list-group.p-2
          b-list-group-item(active) Remove Member From Database
          b-list-group-item.text-center
            p(v-if="globalState.userRole >= 4") Warning: can not be undone!
            p(v-else) Dangerous - for admins only
            b-button(variant="danger" @click="deleteMember(member)" :disabled="globalState.userRole < 4") Delete Member

      div.col-md-8
        b-alert(show variant="info" v-if="globalState.userRole < 5") Some fields have been disabled to prevent ugly database errors that are hard to fix later. They are for developers only.
        b-form
          b-form-row
            b-form-group.col-sm-4(label="Membership Type *" label-for="type")
              b-select(name="type" v-model="editedMember.membershipType" :disabled="globalState.userRole < 5" required)
                option Individual
                option Unemployed
                option Student
                option Lifetime
          b-form-row
            b-form-group.col-sm(label="First Name *" label-for="firstName")
              b-input(type="text" name="firstName" v-model="editedMember.firstName" required)
            b-form-group.col-sm(label="Last Name *" label-for="lastName")
              b-input(type="text" name="lastName" v-model="editedMember.lastName" required)
          b-form-row
            b-form-group.col-sm(label="Email *" label-for="email")
              b-input(type="email" name="email" v-model="editedMember.email" required)
            b-form-group.col-sm(label="Place of Residence *" label-for="city")
              b-input(type="text" name="city" v-model="editedMember.postOffice")
          b-form-row
            b-form-group.col-sm(label="Created" label-for="created")
              datepicker(name="created" v-model="editedMember.createdDate" required :disabled="globalState.userRole < 5" :bootstrap-styling="true")
            b-form-group.col-sm(label="Renewed" label-for="renewed")
              datepicker(name="renewed" v-model="editedMember.lastRenewed" required :disabled="globalState.userRole < 5" :bootstrap-styling="true")
            b-form-group.col-sm(label="Valid Until" label-for="valid")
              datepicker(name="valid" v-model="editedMember.validUntil" required :disabled="globalState.userRole < 5" :bootstrap-styling="true")
          b-form-row
            b-form-group.col-sm(label="IGDA Finland ID" label-for="fiID")
              b-input(type="text" name="fiID" v-model="editedMember.igdaFinlandId" required :disabled="globalState.userRole < 5")
            b-form-group.col-sm(label="IGDA.org ID" label-for="orgId")
              b-input(type="text" name="orgId" v-model="editedMember.igdaOrgId" required :disabled="globalState.userRole < 5")
          b-form-row.justify-content-center.m-4
            b-button(variant="success" @click="updateMember(editedMember)" :disabled="!isFormValid") Update Member
</template>

<script>
import globalState from '@/main.js'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data: function () {
    return {
      member: {
        membershipType: null
      },
      editedMember: {
        membershipType: null
      },
      globalState,
      status: ''
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.editedMember.firstName === '' ||
        this.editedMember.lastName === '' ||
        this.editedMember.email === '' ||
        this.editedMember.postOffice === '') { result = false }
      return result
    }
  },
  mounted: function () {
    this.axios.get('/api/individual-members/' + this.$route.params.id)
      .then(res => {
        this.member = res.data
        this.member.validUntil = new Date(this.member.validUntil)
        this.editedMember = JSON.parse(JSON.stringify(this.member))
        this.editedMember.validUntil = new Date(this.editedMember.validUntil)
        this.editedMember.createdDate = new Date(this.editedMember.createdDate)
        if (this.editedMember.hasOwnProperty('lastRenewed')) { this.editedMember.lastRenewed = new Date(this.editedMember.lastRenewed) } else { this.editedMember.lastRenewed = new Date(0) }

        if (this.member.membershipType === 'Lifetime') { this.status = 'LIFETIME' } else if (this.member.validUntil > new Date()) { this.status = 'VALID' } else { this.status = 'EXPIRED' }
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching member data failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })
  },
  methods: {
    renewMembership: function () {
      if (this.member.validUntil < new Date()) {
        this.member.validUntil = new Date()
      }
      this.member.validUntil.setFullYear(this.member.validUntil.getFullYear() + 1, this.member.validUntil.getMonth() + 1, 0)
      this.member.validUntil.setHours(23, 59, 59)
      this.member.lastRenewed = new Date()

      this.axios.put('/api/individual-members/' + this.member._id, this.member)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Membership renewed',
            text: this.member.firstName + ' now has one more year on the clock!'
          })

          this.$router.push({ path: '/individual-members' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Renewing membership failed',
            text: err.hasOwnProperty('response') ? err.response.data : err,
            duration: -1
          })
        })
    },
    changeMembership: function () {
      if (this.member.validUntil < new Date()) {
        this.member.validUntil = new Date()
      }
      this.member.validUntil.setFullYear(this.member.validUntil.getFullYear() + 1, this.member.validUntil.getMonth() + 1, 0)
      this.member.validUntil.setHours(23, 59, 59)
      this.member.lastRenewed = new Date()
      this.member.membershipType = this.editedMember.membershipType

      this.axios.put('/api/individual-members/' + this.member._id, this.member)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Membership changed & renewed',
            text: this.member.firstName + ' now has one more year on the clock!' // No custom message for lifetime upgrades. Boo!
          })

          this.$router.push({ path: '/individual-members' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Changing membership failed',
            text: err.hasOwnProperty('response') ? err.response.data : err,
            duration: -1
          })
        })
    },
    deleteMember: function () {
      this.axios.delete('/api/individual-members/' + this.member._id)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Member deleted'
          })

          this.$router.push({ path: '/individual-members' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting member failed',
            text: err.hasOwnProperty('response') ? err.response.data : err,
            duration: -1
          })
        })
    },
    updateMember: function (member) {
      this.axios.put('/api/individual-members/' + this.member._id, member)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Member updated',
            text: res.data.firstName + ' is now up-to-date!'
          })

          this.$router.push({ path: '/individual-members' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating member failed',
            text: err.hasOwnProperty('response') ? err.response.data : err,
            duration: -1
          })
        })
    }
  }
}
</script>
